<template>
	<div>
		<div
			:class="[
				'flex items-center',
				markdown ? 'pb-2' : 'pb-1'
			]"
		>
			<label
				:for="id"
				:class="[
					{ 'mr-4': markdown },
					required ? `after:content-['*'] after:font-semibold after:text-red-500`: '',
					labelClasses
				]"
			>
				{{ label }}
			</label>
			<!-- Hyperlink Markdown Tool -->
			<button
				v-if="markdown"
				:disabled="!markdownButtonEnabled"
				:class="[
					'w-6 h-6 rounded-md flex flex-none items-center justify-center',
					markdownButtonEnabled ? 'bg-mx-gray-100 dark:bg-mx-green-800 shadow-sm hover:shadow hover:text-black hover:bg-mx-gray-100 dark:text-mx-gray-400 dark:hover:text-mx-gray-300 text-mx-gray-500 dark:hover:bg-mx-green-600' : 'cursor-not-allowed text-mx-gray-300 dark:text-mx-green-600 dark:bg-mx-green-800/70 shadow-inner bg-mx-gray-50'
				]"
				@click="convertToMarkdownLink"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="1em"
					height="1em"
					viewBox="0 0 24 24"
				>
					<path
						fill="none"
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M13.828 10.172a4 4 0 0 0-5.656 0l-4 4a4 4 0 1 0 5.656 5.656l1.102-1.101m-.758-4.899a4 4 0 0 0 5.656 0l4-4a4 4 0 0 0-5.656-5.656l-1.1 1.1"
					/>
				</svg>
			</button>
		</div>
		<div class="relative">
			<textarea
				:id="id"
				ref="textAreaRef"
				v-model="localValue"
				:rows="rows"
				:class="textAreaClasses"
				:required="required"
				:maxlength="maxLength"
				@select="handleSelection"
				@paste="clearSelection"
				@blur="enableErrorMessage = true"
			/>

			<i
				:class="[required && !localValue.length && enableErrorMessage ? 'block -bottom-3 opacity-100' : 'opacity-0 bottom-0']"
				class="absolute left-0 text-xs transition-all duration-100 text-mx-red dark:text-mx-red"
			>
				{{ errorMessage }}
			</i>

			<!-- Preview of the rendered markdown -->
			<template v-if="showMarkdownPreview">
				<div class="mt-2 mb-1 text-sm">
					Markdown Preview
				</div>
				<div
					class="p-4 text-mx-gray-500 bg-mx-gray-200 rounded-md [&_a]:text-mx-orange hover:[&_a]:underline decoration-2 underline-offset-2 dark:bg-mx-green-900/75 dark:text-mx-gray-300"
					v-html="compiledHtml"
				/>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { marked } from 'marked'

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	label: {
		type: String,
		default: ''
	},
	rows: {
		type: String,
		default: '9'
	},
	required: {
		type: Boolean,
		default: false
	},
	labelClasses: {
		type: String,
		default: 'text-lg font-semibold dark:text-mx-gray-300'
	},
	textAreaClasses: {
		type: String,
		default: 'w-full px-3 py-2 rounded-lg shadow-inner bg-mx-gray-100 dark:bg-mx-green-800 dark:text-mx-gray-400 focus:outline-none focus:ring-mx-orange focus:ring-offset-2'
	},
	markdown: {
		type: Boolean,
		default: false
	},
	errorMessage: {
		type: String,
		default: ''
	},
	maxLength: {
		type: Number,
		default: 2000
	}
})

const { markdown } = toRefs(props)

const emit = defineEmits([ 'update:compiledHtml' ])

const PASTE_HELPER_TEXT = 'paste url here'

const textAreaRef = ref<HTMLTextAreaElement | null>(null)
const selectedText = ref('') // The link name
const enableErrorMessage = ref(false)

const localValue = defineModel({ type: String,	default: '' })

const markdownButtonEnabled = computed(() => {
	return !!selectedText.value?.length
})

const compiledHtml = computed(() => {
	if (!markdown.value) { return '' }
	return marked(localValue.value)
})

const showMarkdownPreview = computed(() => markdown.value && typeof compiledHtml.value === 'string' && compiledHtml.value?.includes('</a>'))

// if input is empty, unstage any selected text and disable the markdown button
watch(localValue, () => {
	// wait until the user has interacted with the textarea before enabling the error message
	if (!enableErrorMessage.value) {
		enableErrorMessage.value = true
	}

	if (!localValue.value) {
		clearSelection()
	}
})

// Emit the rendered markdown to the parent component
watch(compiledHtml, (value) => {
	if (!markdown.value) { return }
	emit('update:compiledHtml', value)
})

const handleSelection = () => {
	if (!textAreaRef.value && !markdown.value) { return }

	const start = textAreaRef.value?.selectionStart || 0
	const end = textAreaRef.value?.selectionEnd || 0

	selectedText.value = textAreaRef.value?.value?.substring(start, end) || ''
}

const convertToMarkdownLink = () => {
	if (!textAreaRef.value || !selectedText.value || !markdown.value) { return }

	const start = textAreaRef.value.selectionStart
	const end = textAreaRef.value.selectionEnd

	const before = localValue.value.substring(0, start)
	const after = localValue.value.substring(end)

	localValue.value = `${before}[${selectedText.value}](${PASTE_HELPER_TEXT})` + after

	clearSelection()

	// set focus to the textarea after converting to markdown
	textAreaRef.value.focus()
}

const clearSelection = () => {
	selectedText.value = ''
}
</script>
